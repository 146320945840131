
import TrackList from '../TrackList/TrackList';
import './Playlist.css';

function Playlist(props) {
    const handleNameChange = (event) => {
        props.onNameChange(event.target.value);
    }


    const savePlaylist = () => {
        props.onSave();
    }
    
    return (
    <div className="Playlist">
        <div className='saveItems'>
            <input className="champNomPlaylist" value={props.name} onChange={handleNameChange} />
        </div>
        <TrackList tracks={props.tracks} showButton={false} showButtonMin={true} onMin={props.onMin}/>
        <button className="btn cursor-pointer hover:bg-green-200" onClick={savePlaylist}>Send to Spotify</button>
    </div>
    );
}

export default Playlist;
