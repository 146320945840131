import './App.css';
import Playlist from './components/Playlist/Playlist';
import PlaylistUser from './components/PlaylistUser/PlaylistUser';
import SearchBar from './components/SearchBar/SearchBar';
import SearchResults from './components/SearchResults/SearchResults';
import Spotify from './components/Spotify/Spotify';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
//import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [key, setKey] = useState(0);
  const [token, setToken] = useState("")
  const [playlists, setPlaylists] = useState([]);

  const [playlistName, setPlaylistName] = useState('Choisir un Nom');
  const [playlistTracks, setPlaylistTracks] = useState([
  ]);
  const [resultTracks, setResultTracks] = useState([  // Ajoutez plus de pistes ici
  ]);

  const addTrack = (track) => {
  if (playlistTracks.find(savedTrack => savedTrack.id === track.id)) {
      return;
  }
  setPlaylistTracks(prevTracks => [...prevTracks, track]);
  }
  const removeTrack = (track) => {
    setPlaylistTracks(prevTracks => prevTracks.filter(prevTrack => prevTrack.id !== track.id));
  }
  const updatePlaylistName = (name) => {
    setPlaylistName(name);
  }
  const savePlaylist = () => {
    const trackUris = playlistTracks.map(track => track.uri);
   
    Spotify.savePlaylist(playlistName, trackUris, token).then(playlist => {
    
      setPlaylistName('Nouvelle Playlist');
      setPlaylistTracks([]);
      refreshPlaylists();
    });
};
const refreshPlaylists = () => {
  Spotify.getUserPlaylists(token).then(playlists => setPlaylists(playlists));
};
  
  const search = (term) => {
    Spotify.search(term,token).then(searchResults => {
      setResultTracks(searchResults);
    });
  };

  const deletePlaylist = (idToRemove) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette playlist ?")) {
        Spotify.deletePlaylist(idToRemove, token);
        setPlaylists(playlists.filter(playlist => playlist.id !== idToRemove));
    }
};


useEffect(() => {
  setToken(Spotify.getAccessToken());
}, []);

useEffect(() => {
  if (token){
  Spotify.getUserPlaylists(token).then(playlists => setPlaylists(playlists));
  }
}, [token]); // Le tableau vide signifie que cet effet s'exécute une fois au lancement de l'application
 
useEffect(() => {
  if (token) {
    setTimeout(() => {
      setToken("");
      setKey(prevKey => prevKey + 1); // Incrémente la clé pour forcer le rechargement de l'élément
    }, 3600000); // Réinitialise le token après 1H
  }
}, [token]);


return(      
  <div className="App" key={key}>
      {!token ? (
        <div>
          <h1 style={{ color: 'white', fontStyle: 'italic' }}>
            J'ai envie de te faire un câlin aussi
            <span style={{ color: 'pink' }}> &lt;3</span>
          </h1>
            <Button className = "btn" onClick={() => window.location.href = Spotify.getAuthUrl()} >Connecte toi pleeeease</Button>
        </div>
      ) : (
        // Le contenu de votre application va ici
        <div>
       <h1 className="text-2xl text-red-500 font-bold">C'est looooong sans toi</h1>
       <div className="flex flex-row items-start h-full">
  <div className="flex flex-col h-full">
    <SearchBar className="searchBar" onSearch={search} /> 
    <SearchResults className="flex flex-col h-full" results={resultTracks} onAdd={addTrack} />
  </div>
  <Playlist name={playlistName} onSave={savePlaylist} onMin={removeTrack} tracks={playlistTracks} onNameChange={updatePlaylistName} />
  <div className="flex flex-col h-full">
    <PlaylistUser playlists={playlists} deletePlaylist={deletePlaylist} />
  </div>
</div>
         </div>
      )}
    </div>
    )

      
}

export default App;


/* TO DO*/
/* pour l'auth reprendre tout ça https://www.youtube.com/watch?v=Xcet6msf3eE*/
/*        {statusMessage && <p>{statusMessage}</p>}*/
/* Petit message d'amour sur la page d'authentification si match aurore ?*/
/* eventuelement brancher sur hugging face pour generer une imqge d'amour*/// ...



// ...
