// Spotify.js
//const data = require('../../token.json'); // Assurez-vous que le chemin vers le fichier est correct
//const clientId = data.clientId;
const clientId="c1f9c5ae51954672be307d752bb67232";
//const redirectUri = 'http://localhost:3000';
const redirectUri = 'https://coruscating-cendol-d05bc4.netlify.app'
const authEndpoint = 'https://accounts.spotify.com/authorize';
const responseType = 'token';
const scopes = 'playlist-read-private playlist-modify-private playlist-modify-public';

//   &scope=${scopes.join(' ')}
    
const Spotify = {
    getAccessToken() {
        const hash = window.location.hash
      .substring(1)
      .split('&')
      .reduce(function (initial, item) {
        if (item) {
          var parts = item.split('=');
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {});
     console.log('token inside' + hash.access_token);
     return hash.access_token;
    }
,
     getAuthUrl() {
        //const state = Math.random(); // Génère un nombre aléatoire
        const url = `${authEndpoint}?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&response_type=${responseType}&show_dialog=true`;
        return url;
        },
        search(term, token) {
            const accessToken = token;
            return fetch(`https://api.spotify.com/v1/search?type=track&q=${term}`, {
                headers: { Authorization: `Bearer ${accessToken}` }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la recherche de la piste');
                }
                return response.json();
            })
            .then(data => {
                if (!data.tracks) {
                    return [];
                }
                return data.tracks.items.map(track => ({
                    id: track.id,
                    name: track.name,
                    artist: track.artists[0].name,
                    album: track.album.name,
                    uri: track.uri,
                    image: track.album.images[0].url 
                }));
            });
        },
        savePlaylist(name, trackUris, token, isPublic = true, description = "coucou") {
        
            const accessToken = token;
            const headers = { 
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            };
            let userId;

            return fetch('https://api.spotify.com/v1/me', { headers: headers })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération de l\'utilisateur');
                }
                return response.json();
            })
            .then(data => {
                userId = data.id;

                let response = fetch(`https://api.spotify.com/v1/users/${userId}/playlists`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify({
                        name: name,
                        description: description,
                        public: isPublic
                    })
                });
                return response;
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la création de la playlist');
                }
                return response.json();
            })
            .then(data => {
                const playlistId = data.id;
                return fetch(`https://api.spotify.com/v1/users/${userId}/playlists/${playlistId}/tracks`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify({ uris: trackUris })
                });
            }).catch(error => {
                console.error('Une erreur est survenue :', error);
              });;
        },
        getUserPlaylists(token) {
            const accessToken = token;
            let userId;
          
            return fetch('https://api.spotify.com/v1/me', {
              headers: { Authorization: `Bearer ${accessToken}` }
            })
            .then(response => response.json())
            .then(data => {
              userId = data.id;
              return fetch(`https://api.spotify.com/v1/users/${userId}/playlists`, {
                headers: { Authorization: `Bearer ${accessToken}` }
              });
            })
            .then(response => response.json())
            .then(data => {
              return data.items.map(playlist => ({
                id: playlist.id,
                name: playlist.name,
                tracks: playlist.tracks.href // URL pour récupérer les pistes de la playlist
              }));
            });
          },
          deletePlaylist(id,token) {
            const accessToken = token;
            fetch(`https://api.spotify.com/v1/playlists/${id}/followers`, {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${accessToken}` }
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la suppression de la playlist');
                }
            })
            .catch(error => console.error(error));}

};

export default Spotify;