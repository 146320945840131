/*react component which allow to display the list of the name of the plaulist of the user*/
import React from 'react';

function PlaylistUser(props) {
    console.log(props.playlists);
    const deletePlaylist = (id) => {
        console.log(id);
        props.deletePlaylist(id);
    }
    return (
        <div className="PlaylistUser">
            <h2>Liste des playlists sur Spotify</h2> {/* Ajoute un titre au-dessus de la liste des playlists */}
            {props.playlists.map(playlist => (
                <div key={playlist.id} className="flex items-center bg-black-500 p-2 rounded-lg hover:bg-blue-700 cursor-pointer">
                    <p className="mr-4 text-white">{playlist.name}</p>
                    <button className="btn ml-auto bg-white border-blue-500 text-blue-500 rounded-full w-6 h-6 flex items-center justify-center" onClick={() => deletePlaylist(playlist.id)}>X</button>
                </div>
            ))}
        </div>
    );  
}

export default PlaylistUser;
