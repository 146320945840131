import TrackList from '../TrackList/TrackList';
import './SearchResults.css';
function SearchResults(props) {
    return (
        <div className="SearchResults">
            <TrackList tracks={props.results} onAdd={props.onAdd} showButton={true}/>
        </div>
    );
}

export default SearchResults;