import React from 'react';
import './Track.css';

function Track(props) {
    const addTrack = () => {
        props.onAdd(props.track);
    }
    const removeTrack = () => {
        props.onMin(props.track);
    }

    
    return (
        <div className="Track">
            <div className="Track-information flex-grow">
                <img className="Track-image" src={props.track.image} alt={props.track.name} /> {/* Ajoutez la classe Track-image */}
                <div className = "Track-details">
                    <p className="Track-title">{props.track.name} </p> 
                    <p className="Track-artist">{props.track.artist}</p>  
                </div>
            </div>
            <div className="flex items-center">
                 {props.showButton && <button className="btn" onClick={addTrack}>+</button>}
                {props.showButtonMin && <button className="btn ml-2" onClick={removeTrack}>-</button>}
            </div>
        </div>
    );
}


export default Track;

