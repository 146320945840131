/* import react and react native modules */
import React, { useState} from 'react';
import './SearchBar.css';

function SearchBar(props) {
    const [term, setTerm] = useState('');

  const search = (e) => {
    e.preventDefault();
    props.onSearch(term);
  };

  return (
    <form onSubmit={search}>
      <input type="text" value={term} onChange={e => setTerm(e.target.value)} />
      <button type="submit" className="searchButton">
        <img className ="img" src="https://cdn-icons-png.flaticon.com/512/3721/3721591.png" alt="Rechercher" />
      </button>
    </form>
);

}



export default SearchBar;
