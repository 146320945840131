import Track from '../Track/Track';

function TrackList(props) {
    return (
        <div className="TrackList">
            {props.tracks.map(track => 
                <Track key={track.id} track={track} onAdd={props.onAdd} onMin={props.onMin} showButton={props.showButton} showButtonMin={props.showButtonMin}/>
            )}
        </div>
    );
}

export default TrackList;